<template>
  <app-view-container>
    <ValidationObserver ref="form" tag="form" @submit.prevent="submitPassword">
      <template v-if="!submitted">
        <v-card-title class="d-block text-center text-h3 semi-bold pa-6">
          {{ $t('resetPassword.title') }}
        </v-card-title>

        <v-card-text class="body-1 py-2 px-6">
          <div class="mt-2 mb-6">
            {{ $t('resetPassword.info') }} {{ $t('resetPassword.passwordRequirements') }}
          </div>
          <app-alert class="mb-4" :value="oldPasswordUsed">
            {{ $t('resetPassword.oldPasswordUsed') }}
          </app-alert>
          <confirm-password :password.sync="password" :confirmation.sync="confirmation" />
        </v-card-text>

        <v-spacer />

        <v-divider />
        <v-card-actions class="px-6">
          <v-row>
            <v-col>
              <app-btn
                type="submit"
                block
                :loading="loading"
                :disabled="!password || !confirmation"
                title="resetPassword.updateNow"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </template>

      <template v-else>
        <v-card-title class="d-block text-center text-h3 semi-bold pa-6">
          {{ $t('resetPassword.updateComplete') }}
        </v-card-title>

        <v-card-text class="body-1 py-2">
          <div class="mt-2 mb-6">{{ $t('resetPassword.confirmUpdate') }}</div>
        </v-card-text>

        <v-spacer />

        <v-divider />
        <v-card-actions class="px-6">
          <v-row>
            <v-col>
              <app-btn
                block
                :loading="loading"
                title="navigation.toPortal"
                @click="redirectToDashboard"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
    </ValidationObserver>
  </app-view-container>
</template>

<script>
import { NAMESPACE } from '@/modules/Authentication/store';
import { mapActions } from 'vuex';
import showSnackbar from '@/helper/showSnackbar';
import { ERROR_CODE_OLD_PASSWORD } from '@/statics/errorCodes';
import ConfirmPassword from '@/shared/components/ConfirmPassword';

export default {
  name: 'UpdatePassword',

  components: { ConfirmPassword },

  data: () => ({
    password: '',
    confirmation: '',
    loading: false,
    submitted: false,
    oldPasswordUsed: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['updatePassword']),

    async submitPassword() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await this.updatePassword(this.password);
      this.loading = false;

      if (error) {
        if (error.response?.data.error === ERROR_CODE_OLD_PASSWORD) {
          this.oldPasswordUsed = true;
          return;
        }
        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
    },

    redirectToDashboard() {
      this.loading = true;
      this.$auth
        .fetch({})
        .then(() => {
          this.loading = false;
          this.$router.replace({ name: this.defaultRoute.route });
        })
        .catch(() => {
          showSnackbar({ text: 'serverError', color: 'error' });
          this.loading = false;
        });
    }
  }
};
</script>
